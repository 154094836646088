/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { Fragment, useState } from "react";
import {
  Typography,
  Space,
  Checkbox,
  Input,
  Table,
  Tag,
  message,
  Popconfirm,
  Button,
  Pagination,
  Spin,
  Modal,
  Form,
} from "antd";
import api from "../../api";
import {
  DeleteOutlined,
  HighlightOutlined,
  LoadingOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Upload from "../../components/Upload";
import InputMask from "react-input-mask";
import { ChangePasswordUser } from "../../components";
export default function Users() {
  const history = useHistory();
  const [ROLE] = React.useState(localStorage.getItem("role"));
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [refetch, setRefetch] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searchId, setSearchId] = React.useState("");
  const [page, setPage] = React.useState(parseInt(useLocation().search[6]));
  const [meta, setMeta] = React.useState([1, 2, 3]);
  const [metadata, setMetadata] = React.useState({});
  const [_staffs, setStaffs] = React.useState([]);
  console.log(`************ ~ Users ~ _staffs:`, _staffs);

  React.useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/company/all");
        setStaffs(data.data);
      } catch (err) {}
    })();
  }, []);
  React.useEffect(() => {
    getUsers(search, searchId, meta, page);
  }, [refetch, search, searchId, meta, page]);

  React.useEffect(() => {
    setPage(1);
  }, [search, searchId]);

  async function updateSupporter(userId, supporter) {
    try {
      await api.put("/users/supporter", { userId, supporter });
      setRefetch((prev) => !prev);
      message.success("Cập nhật thành công.");
    } catch (err) {
      message.error(err);
    }
  }
  async function deleteUser(userId) {
    try {
      await api.delete(`/users/${userId}`);
      setRefetch((prev) => !prev);
      message.success("Xoá thành công.");
    } catch (err) {
      message.error("Có lỗi xảy ra, vui lòng thử lại sau.");
    }
  }
  async function lockUser(data) {
    try {
      await api.put(`/users/lock-user`, {
        userId: data._id,
        locked: true,
      });
      setRefetch((prev) => !prev);
      message.success("Khoá thành công.");
    } catch (err) {
      message.error("Có lỗi xảy ra, vui lòng thử lại sau.");
    }
  }
  async function unlockUser(data) {
    try {
      await api.put(`/users/lock-user`, {
        userId: data._id,
        locked: false,
      });
      setRefetch((prev) => !prev);
      message.success("Mở khoá thành công.");
    } catch (err) {
      message.error("Có lỗi xảy ra, vui lòng thử lại sau.");
    }
  }
  async function getUsers(search, searchId, meta, page) {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/users?page=${
          page || 1
        }&search=${search}&searchId=${searchId}&meta=${meta.map(
          (item) => item
        )}`
      );
      setMetadata(data);
      setData((prev) => [...data.docs]);
    } catch (err) {
      message.error("Xảy ra lỗi, vui lòng thử lại sau.");
    } finally {
      setLoading(false);
    }
  }

  const renderText = (text) => {
    const staff = _staffs.find((item) => item.refCode == text);
    if (staff) {
      return staff.phone;
    }
    return text;
  };

  const columns = [
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "NV Hỗ trợ",
      dataIndex: "supporter",
      key: "active_code",
      with: "50%",
      render: (text, record) => {
        return (
          <>
            <Typography.Text
              // editable={
              //   ROLE == "ROOT"
              //     ? {
              //         icon: <HighlightOutlined />,
              //         tooltip: "Click để đổi Nhân viên",
              //         onChange: (e) => updateSupporter(record._id, e),
              //       }
              //     : false
              // }
              editable={false}
            >
              {/* {_staffs.filter((item) => item.refCode == text).length > 0 &&
              _staffs.filter((item) => item.refCode == text)[0]?.phone
                ? _staffs.filter((item) => item.phone == text)[0]?.phone
                : record.active_code} */}
              {renderText(record.active_code)}
              {/* {record.supporter} */}
            </Typography.Text>
          </>
        );
      },
    },
    {
      title: "Tên ",
      dataIndex: "kyc",
      key: "kyc",
      render: (text, record) => <>{record.kyc?.name ? record.kyc?.name : ""}</>,
    },
    {
      title: "Trạng thái hồ sơ ",
      dataIndex: "kyc",
      key: "status",
      render: (text, record) => (
        <>
          {record.kyc?.status === "accepted" && (
            <Tag color="success">Đã duyệt hồ sơ</Tag>
          )}
          {record.kyc?.status === "pending" && (
            <Tag color="processing">Đã tạo hồ sơ</Tag>
          )}
          {record.kyc?.status === "yet" && (
            <Tag color="processing">Đã tạo hồ sơ</Tag>
          )}
          {!record.kyc?.status && <Tag color="orange">Chưa xác minh</Tag>}
        </>
      ),
    },
    {
      title: "Khởi tạo lúc ",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => (
        <Typography.Text style={{ fontSize: 12 }}>
          {moment(text).format("hh:mm A, DD-MM-YYYY")}
        </Typography.Text>
      ),
    },
    {
      title: "Người cập nhật ",
      dataIndex: "updated_by",
      key: "updated_by",
      render: (obj, record) => (
        <Typography.Text style={{ fontSize: 14 }}>{obj?.phone}</Typography.Text>
      ),
    },
    {
      title: "Tuỳ chọn",
      key: "action",
      render: (text, record) => (
        <Space size="large">
          <a
            onClick={() =>
              record.kyc?.status
                ? history.push(`/detail?id=${record._id}`)
                : message.info("Khách hàng chưa khởi tạo hồ sơ.")
            }
          >
            Xem chi tiết
          </a>
          {ROLE == "ROOT" && (
            <>
              <Popconfirm
                title="Không thể khôi phục. Chắc chắn xoá ?"
                onConfirm={() => deleteUser(record._id)}
              >
                <Button icon={<DeleteOutlined />} danger shape="round">
                  Xoá
                </Button>
              </Popconfirm>
              {!record.isLocked ? (
                <Popconfirm
                  title="Bạn có chắc chắn muốn khoá người dùng này?"
                  onConfirm={() => lockUser(record)}
                >
                  <Button icon={<LockOutlined />} danger shape="round">
                    Khoá
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Bạn có chắc chắn muốn mở khoá người dùng này?"
                  onConfirm={() => unlockUser(record)}
                >
                  <Button icon={<UnlockOutlined />} shape="round">
                    Mở khoá
                  </Button>
                </Popconfirm>
              )}
            </>
          )}

          {ROLE == "ADMIN" && (
            <>
              {!record.isLocked ? (
                <Popconfirm
                  title="Bạn có chắc chắn muốn khoá người dùng này?"
                  onConfirm={() => lockUser(record)}
                >
                  <Button icon={<LockOutlined />} danger shape="round">
                    Khoá
                  </Button>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Bạn có chắc chắn muốn mở khoá người dùng này?"
                  onConfirm={() => unlockUser(record)}
                >
                  <Button icon={<UnlockOutlined />} shape="round">
                    Mở khoá
                  </Button>
                </Popconfirm>
              )}
            </>
          )}
        </Space>
      ),
    },
    {
      title: "Tuỳ chọn",
      key: "action",
      render: (text, record) => (
        <>
          {(ROLE == "ROOT" || ROLE == "ADMIN") && (
            <ChangePasswordUser userId={record._id} />
          )}
        </>
      ),
    },
  ];
  async function resetPassword({ password, userId }) {
    try {
      await api.put("/auth/password", { userId, password });
      message.success("Reset password thành công.");
    } catch (err) {
      message.error("Đã có lỗi xảy ra. Vui lòng thử lại sau");
    }
  }

  return (
    <Fragment>
      <Optional
        setSearch={setSearch}
        setSearchId={setSearchId}
        setMeta={setMeta}
        meta={meta}
      />

      {loading ? (
        <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
      ) : (
        <>
          <Table columns={columns} dataSource={data} pagination={false} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 10,
            }}
          >
            <Pagination
              total={metadata.totalDocs}
              current={page}
              onChange={(e) => setPage(e)}
              pageSize={10}
              pageSizeOptions={["10"]}
            />
          </div>
        </>
      )}
    </Fragment>
  );
}

const Optional = ({ setSearch, setMeta, setSearchId, meta }) => {
  const [querySearch, setQuerySearch] = React.useState("");
  const [querySearchId, setQuerySearchId] = React.useState("");
  const dispatch = useDispatch();
  const router = useHistory();
  React.useEffect(() => {
    const timeout = setTimeout(() => setSearch((prev) => querySearch), 500);
    return () => clearTimeout(timeout);
  }, [querySearch]);

  React.useEffect(() => {
    const timeout = setTimeout(() => setSearchId((prev) => querySearchId), 500);
    return () => clearTimeout(timeout);
  }, [querySearchId]);
  const [visible, setVisible] = useState(false);
  const [idFront, setIdFront] = useState("");
  const [idBack, setIdBack] = useState("");
  const onFinish = async (e) => {
    try {
      let payload = {};
      payload["phone"] = e.phone;
      payload["password"] = e.phone;
      payload["kyc"] = {
        address: e.address,
        dob: e.dob,
        id_back: idBack,
        id_front: idFront,
        id_number: e.id_number,
        income: e.income,
        job: e.job,
        purpose: e.purpose,
        name: e.name,
        bank: {
          bank_name: e.bank_name,
          bank_owner: e.bank_owner,
          bank_number: e.bank_number,
        },
      };
      payload["contract"] = {
        amount: e.amount,
        times: e.times,
      };
      const { data } = await api.post("/users/create-user", { ...payload });
      message.success("Tạo tài khoản thành công.");
    } catch (err) {
      message.error(err);
    }
  };

  return (
    <Fragment>
      <div>
        <Typography.Title level={5}>Tuỳ chọn</Typography.Title>
        <Button
          shape="round"
          icon={<UserAddOutlined />}
          onClick={() => setVisible(true)}
        >
          Thêm
        </Button>
      </div>
      <Space>
        <div style={{ paddingRight: 15 }}>
          <Typography.Text style={{ padding: 5, fontSize: 15 }}>
            Chưa xác minh
          </Typography.Text>
          <Checkbox
            defaultChecked
            onChange={() =>
              setMeta((prev) =>
                prev.includes(1)
                  ? prev.filter((item) => item != 1)
                  : [...prev, 1]
              )
            }
          />
        </div>
        <div style={{ paddingRight: 15 }}>
          <Typography.Text style={{ padding: 5, fontSize: 15 }}>
            Đã xác minh
          </Typography.Text>
          <Checkbox
            defaultChecked
            onChange={() =>
              setMeta((prev) =>
                prev.includes(2)
                  ? prev.filter((item) => item != 2)
                  : [...prev, 2]
              )
            }
          />
        </div>
        <div style={{ paddingRight: 15 }}>
          <Typography.Text style={{ padding: 5, fontSize: 15 }}>
            Đã tạo hồ sơ
          </Typography.Text>
          <Checkbox
            defaultChecked
            onChange={() =>
              setMeta((prev) =>
                prev.includes(3)
                  ? prev.filter((item) => item != 3)
                  : [...prev, 3]
              )
            }
          />
        </div>
      </Space>
      <div style={{ padding: "20px 0px " }}>
        <Space>
          <Input.Search
            placeholder="Số điện thoại"
            onChange={(e) => setQuerySearch(e.target.value)}
          />
          <Input.Search
            placeholder="CMNN / CCCD"
            onChange={(e) => setQuerySearchId(e.target.value)}
          />
        </Space>
      </div>
      <Modal
        visible={visible}
        title={null}
        footer={null}
        closable={true}
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
      >
        <Form wrapperCol={{}} layout="vertical" onFinish={onFinish}>
          <Form.Item
            name="name"
            label="Họ tên"
            rules={[
              {
                required: true,
                message: "Nhập tên của bạn",
              },
            ]}
          >
            <Input placeholder="Nhập họ tên" />
          </Form.Item>
          <Form.Item
            label="Ngày sinh"
            rules={[
              {
                required: true,
                message: "Nhập ngày sinh của bạn",
              },
            ]}
            name="dob"
          >
            <InputMask
              mask="99/99/9999"
              maskPlaceholder="dd/mm/yyyy"
              placeholder="Ngày / Tháng / Năm"
              style={{
                borderRadius: 5,
                padding: "5px 7px",
                fontSize: 14,
                width: "100%",
                border: "1px solid #eaeaea",
              }}
              className="mask-focus"
            />
          </Form.Item>
          <Form.Item
            name="id_number"
            label="Số CMND/CCCD"
            rules={[
              {
                required: true,
                message: "Nhập CMND/CCCD của bạn",
              },
            ]}
          >
            <Input placeholder="CMND/CCCD" />
          </Form.Item>
          <Form.Item label="Hình ảnh CMND/CCCD">
            <Upload type={"front"} onDispatch={(e) => setIdFront(e)} />
            <Upload type={"back"} onDispatch={(e) => setIdBack(e)} />
          </Form.Item>
          <Form.Item
            name="address"
            label="Địa chỉ"
            rules={[
              {
                required: true,
                message: "Nhập Địa chỉ của bạn",
              },
            ]}
          >
            <Input placeholder="Địa chỉ" />
          </Form.Item>
          <Form.Item
            name="job"
            label="Nghề nghiệp"
            rules={[
              {
                required: true,
                message: "Điền nghề nghiệp của bạn",
              },
            ]}
          >
            <Input placeholder="Điền nghề nghiệp" />
          </Form.Item>
          <Form.Item
            name="income"
            label="Thu nhập / tháng"
            rules={[
              {
                required: true,
                message: "Nhập Thu nhập của bạn",
              },
            ]}
          >
            <Input placeholder="Thu nhập" />
          </Form.Item>
          <Form.Item
            name="purpose"
            label="Mục đích vay tiền"
            rules={[
              {
                required: true,
                message: "Mục đích vay tiền",
              },
            ]}
          >
            <Input placeholder="Mục đích vay tiền" />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Số điện thoại đăng nhập"
            rules={[
              {
                required: true,
                message: "Cần nhập số điện thoại đăng nhập",
              },
            ]}
          >
            <Input placeholder="Điện thoại đăng nhập" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Mật khẩu đăng nhập"
            rules={[
              {
                required: true,
                message: "Mật khẩu đăng nhập",
              },
            ]}
          >
            <Input placeholder="Mật khẩu đăng nhập" type={"password"} />
          </Form.Item>
          <Form.Item
            name="bank_name"
            label="Tên ngân hàng"
            rules={[
              {
                required: true,
                message: "Cần nhập Tên ngân hàng",
              },
            ]}
          >
            <Input placeholder="Tên ngân hàng" />
          </Form.Item>
          <Form.Item
            name="bank_owner"
            label="Tên chủ thẻ"
            rules={[
              {
                required: true,
                message: "Cần nhập Tên chủ thẻ",
              },
            ]}
          >
            <Input placeholder="Tên chủ thẻ" />
          </Form.Item>
          <Form.Item
            name="bank_number"
            label="Số tài khoản"
            rules={[
              {
                required: true,
                message: "Cần nhập Số tài khoản ngân hàng",
              },
            ]}
          >
            <Input placeholder="Số tài khoản ngân hàng" />
          </Form.Item>
          <Form.Item
            name="amount"
            label="Số tiền vay"
            rules={[
              {
                required: true,
                message: "Cần nhập  Số tiền vay",
              },
            ]}
          >
            <Input placeholder=" Số tiền vay" />
          </Form.Item>
          <Form.Item
            name="times"
            label="Thời hạn"
            rules={[
              {
                required: true,
                message: "Thời hạn",
              },
            ]}
          >
            <Input placeholder="Thời hạn" type={"number"} />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button type="primary" shape="round" htmlType="submit">
              Tiếp tục
            </Button>
          </div>
        </Form>
      </Modal>
    </Fragment>
  );
};
